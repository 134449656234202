/* >>> Extract to our own Alert wrapper component */
.status-alert {
    margin-bottom: 16px;
    border-radius: 2px;
}

.status-alert .model-version-status-icon {
    margin-left: -3px;
}

.status-alert.status-alert-info .icon-pending {
    color: green;
    margin-right: 4px;
}

.status-alert.status-alert-info {
    border-left: 2px solid #3895d3;
}

.status-alert.status-alert-error {
    border-left: 2px solid red;
}


.breadcrumb-header-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.breadcrumb-header {
    font-size: 18px;
    margin-bottom: 24px;
    font-weight: bold;
    color: #333;
    display:flex;
    align-items: center;
    margin-right: 10px;
}

.version-follow-icon {
    margin-left: auto;
}

.ant-popover-content {
    max-width: 500px;
}
/* <<< Extract to our own Alert wrapper component */
