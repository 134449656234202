.center {
  text-align: center;
}
.center {
  text-align: center;
}

.error-image {
  margin: 12% auto 60px;
  display: block;
}
