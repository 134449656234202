@import 'components/ModelVersionTable.css';
@import 'components/ModelVersionView.css';
@import 'components/ModelStageTransitionDropdown.css';

/** TODO(Zangr) migrate globally common components and styles into src/common folder */
.metadata-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.metadata-entry {
  margin-bottom: 16px;
}

.icon-pending,
.icon-ready {
  color: green;
}

.icon-fail {
  color: red;
}
