.collapsible-section.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex; /* restrict toggleable area */
  align-items: center;
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;
}

.collapsible-section .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}

.collapsible-section .ant-collapse-item {
  border: 0;
  overflow: hidden;
}
